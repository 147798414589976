<template>
  <a-drawer
    :title="fundraiser ? 'Fundraiser Story' : ' Highlighted Current Event'"
    placement="right"
    :closable="true"
    :visible="showDrawer"
    @close="closeDrawer"
    width="100rem"
    wrapClassName="the-story-ditails"
  >
  
    <div class="story-drawer-skeleton" v-if="storySkeletonLoader">
      <a-skeleton
        active
        :title="{ width: '30%' }"
        :paragraph="{ rows: 2, width: ['80%', '100%'] }"
      />
    </div>

    <div v-if="story">
      <div class="header">
        <h3>{{ story.title }}</h3>
        <div class="date">
          <p>Published Date:</p>
          <h4>{{ story.created_at }}</h4>
        </div>
        <div class="user-details">
          <div class="user">
            <div class="user-img" @click="redirectToUser(story.author.id)">
              <img :src="story.author.profile.profile_picture" alt="" />
            </div>
            <p @click="redirectToUser(story.author.id)">{{ story.author.profile.full_name }}</p>
          </div>
          <!-- <span></span> -->
          <!-- <p>New York, United States</p> -->
        </div>
        <div v-if="fundraiser" class="navigation">
          <span @click="select('story')" :class="{ active: isActive('story') }"
            >Story</span
          >
          <span @click="select('funds')" :class="{ active: isActive('funds') }"
            >Funding Rounds</span
          >
          <span
            @click="select('updates')"
            :class="{ active: isActive('updates') }"
            >Updates</span
          >
        </div>
      </div>
      <div class="body">
        <div class="user-media-details">
          <div class="attached-media">
            <h2>Attached Media</h2>
            <div class="slides" v-if="story.media.length !== 0">
              <button
                class="arrows"
                id="prev-btn"
                @click="moveImage(-1)"
                ref="previous"
                :class="{'disable': currentIndex === 0}"
              >
                <i class="icon icon-forward left-arrow"></i>
              </button>
              <button
                class="arrows"
                id="next-btn"
                @click="moveImage(1)"
                ref="next"
                :class="{'disable': currentIndex >= story.media.length - 1}"
              >
                <i class="icon icon-forward right-arrow"></i>
              </button>
            </div>
          </div>
          <div class="slide-box">
            <div class="container" id="slider" v-if="story.media.length !== 0">
              <div class="image" v-for="image in story.media" :key="image">
                <a-image
                   :src="image.media"
                />
              </div>
            </div>
            <div v-else class="not-found">
              <h3>No media found</h3>
            </div>
          </div>
          <h2>Description</h2>
          <div class="details">
            <p>
              {{ story.description }}
            </p>
          </div>
          <h2>Location</h2>
          <div class="location" >
            {{ locationTags }}
            <!-- <TagifyAjax workingOn="locations" :readonly="true" :prevTags="selectedLocations" /> -->
          </div>

          <div class="comment-section">
            <h2 class="comment-section--heading">Comments:</h2>
            <story-comments
              v-for="(comment, index) in comments"
              :key="index"
              :profilePicture="comment.profile_picture"
              :fullName="comment.full_name"
              :comment="comment.comment"
              :commentsReplies="comment.child"
              :storyId="comment.impact_story"
              :parentId="comment.id"
              :displayPicture="userInformation.profile_picture"
              :time="comment.human_time"
              :commentBy="comment.user_id"
              :commentFor="'impact'"
            ></story-comments>

            <div class="comment-input">
              <div class="user-image">
                <img :src="userInformation.profile_picture" alt="User image" />
              </div>
              <div class="send-comment">
                <textarea
                data-cy="storyComments"
                  id=""
                  rows="2"
                  placeholder="Write a comment..."
                  v-model="addComment"
                ></textarea>
                <the-loader v-if="loading" class="comment-textarea-loader"></the-loader>
                <button data-cy="sendStoryComments" :disabled="loading" class="send-btn" @click="createComment()">
                  <i class="icon icon-send"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="footer">
      <button @click="$emit('show-modal')" class="donate-btn" v-if="fundraiser">
        Donate now
      </button>
      <button v-else>Write about this</button>
    </div> -->
    </div>
    <p class="not-found" v-else>This highlighted current event may be removed.</p>
  </a-drawer>
</template>

<script>
import StoryComments from "../BaseComponents/commentsSection/Comments.vue";
// import TagifyAjax from "../PdbComponents/TagifyAjax.vue"
import TheLoader from "../BaseComponents/TheLoader.vue";

export default {
  components: {
    StoryComments,
    TheLoader
    // TagifyAjax
  },
  props: {
    storyId: String,
    showDrawer: Boolean,
    fundraiser: Boolean,

  },
  data() {
    return {
      currentIndex: 0,
      selectedTab: "story",
      comment: false,
      story: null,
      addComment: "",
      loading: false,
      storySkeletonLoader:false,
    };
  },
  computed: {
    comments() {
      return this.$store.getters["impact/getImpactArticlesComments"].comments;
    },
    userInformation(){
      return this.$store.getters["profile/userInformation"].profile;
    },
    locationTags() { 
      let locationTags = this.story.places.map(place => place.name)
      return locationTags.join()
    }
  },
  watch: {
    showDrawer(){
      this.addComment = "";
    },
    storyId: {
      handler() {
        this.story = null;
        this.getStory();
      },
      immediate: true,
    },
  },
  methods: {
    select(tab) {
      this.selectedTab = tab;
    },
    isActive(tab) {
      return this.selectedTab == tab;
    },
    moveImage(num) {
    let slider = document.getElementById("slider");
    let prev = document.getElementById("prev-btn");
    let next = document.getElementById("next-btn");

    if (this.currentIndex + num < 0) {
      // Disable previous button when at the beginning
      this.currentIndex = 0;
      prev.disabled = true;
      next.disabled = false;
    } else if (this.currentIndex + num >= this.story.media.length -1) {
      // Disable next button when at the end
      this.currentIndex = this.story.media.length -1;
      prev.disabled = false;
      next.disabled = true;
    } else {
      this.currentIndex += num;
      prev.disabled = false;
      next.disabled = false;
    }

    prev.classList.toggle("disable", prev.disabled);
    next.classList.toggle("disable", next.disabled);

    // Update slider position
    slider.style.transition = "1s";
    slider.style.transform = `translate(${this.currentIndex * -12.8}rem, 0)`;
  },
    async getStory() {
      try {
        this.storySkeletonLoader = true
        const response = await this.$store.dispatch(
          "impact/fetchImpactArticleComments",
          this.storyId
        );
        if (response.status === 200) {
          this.story = response.data;
          this.storySkeletonLoader = false
        }
      } catch (err) {
        this.storySkeletonLoader = false
        console.log(err);
      }
    },
    async createComment() {
      try {
        this.loading = true;
        const payload = {
          comment: this.addComment,
          parent: null,
          story_id: this.storyId,
        };
        const response = await this.$store.dispatch(
          "impact/impactArticleComment",
          payload
        );
        if (response.status === 201) {
          this.addComment = "";
          await this.$store.dispatch(
            "impact/fetchImpactArticleComments",
            this.storyId
          );
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    closeDrawer() {
      this.$emit("close-drawer");
    },
    redirectToUser(id) {
      this.$router.push(`/user/${id}/`);
      // console.log(id);
    },
  },
  
};
</script>
<style lang="scss">
.the-story-ditails {
  @include reset-css;
  .ant-drawer-content-wrapper {
    box-shadow: -17px 3px 94px #0000001a;
    @include respond(tab-land) {
      width: 90vw !important;
    }
    // @include respond(laptop-small) {
    //   width: 70rem !important;
    // }
    //  @include respond(tab-port) {
    //   width: 60rem !important;
    // }
    // @include respond(phone-x-small) {
    //   width: 60rem !important;
    // }
  }
  .ant-drawer-wrapper-body {
    background-color: #0000001a !important;
    overflow: hidden;
  }
  .ant-drawer-close {
    top: 5rem;
    right: 2rem;
  }
  .ant-drawer-header {
    padding: 7rem 4rem 4rem;
    border: none;
    background-color: transparent;
    border-bottom: 0.24rem solid lightgrey;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .ant-drawer-close {
      border: none;
      position: initial;
      height: fit-content;
      width: fit-content;
      line-height: 0.5rem;
      .anticon {
        svg {
          width: 2rem;
          height: 2rem;
        }
      }
    }
    .ant-drawer-title {
      font-size: 2rem;
      font-family: $font-secondary-bold;
      font-weight: 900;
    }
  }
  .ant-drawer-body {
    padding: 0;
    .header {
      padding: 0 4rem;
      border-bottom: 0.24rem solid rgba(128, 128, 128, 0.233);
      h3 {
        font-size: 2.4rem;
        font-family: $font-primary-bold;
        line-height: 3.5rem;
      }
      .date {
        display: flex;
        align-items: center;
        margin-top: 1rem;
        p {
          margin-right: 0.7rem;
          font-size: 1.4rem;
          margin-bottom: 0rem;
          opacity: 0.7;
        }
        h4 {
          font-size: 1.3rem;
          font-family: $font-primary-bold;
          margin-bottom: 0;
        }
      }
      .user-details {
        display: flex;
        align-items: center;
        margin: 1.8rem 0;
        .user {
          display: flex;
          align-items: center;
          cursor: pointer;
          .user-img {
            width: 2.7rem;
            margin-right: 1rem;
            img {
              width: 100%;
              border-radius: 100%;
              background-color: rgb(205, 205, 205);
            }
          }
          p {
            font-size: 1.2rem;
            font-family: $font-primary-bold;
          }
        }
        span {
          width: 0.2rem;
          height: 1.7rem;
          opacity: 0.3;
          background-color: $color-black;
          margin: 0 1.5rem;
        }
        p {
          font-size: 1.2rem;
          font-family: $font-primary;
        }
      }
      .navigation {
        margin-bottom: 0.4rem;
        span {
          cursor: pointer;
          font-size: 1.3rem;
          font-family: $font-primary;
          margin-right: 2.2rem;
          color: $color-black;
          opacity: 0.5;
        }
        .active {
          opacity: 1;
          font-family: $font-primary-bold;
          position: relative;
        }
        .active::before {
          content: "";
          width: 2rem;
          height: 0.4rem;
          background-color: $color-primary;
          position: absolute;
          bottom: -1rem;
        }
      }
    }
    .body {
      padding: 2.5rem 4rem;
      padding-right: 2.3rem;
      position: relative;
      .user-media-details {
        height: 54vh;
        overflow-x: hidden;
        overflow-y: auto;
        padding-right: 0.5rem;
        padding-bottom: 1rem;
        @include respond(tab-land) {
          height: 40vh;
        }
        @include respond(laptop-small) {
          height: 40vh;
        }
        h2 {
          font-size: 2rem;
          font-family: $font-primary-bold;
        }
        .attached-media {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 2rem;
          width: 100%;
          .slides {
            display: flex;
            align-items: center;
            .arrows {
              background-color: #373737;
              width: 5rem;
              height: 3.8rem;
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0 0.5rem;
              cursor: pointer;
              border: none;
              .icon-forward {
                margin-right: 0;
              }
              .icon {
                color: $color-white;
                font-size: 1.8rem;
              }
              .left-arrow {
                transform: rotate(180deg);
              }
              .right-arrow {
                transform: rotate(0deg);
              }
            }
            .disable {
              opacity: 0.5;
              cursor: default;
            }
          }
        }
        .slide-box {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 2.5rem;
          width: 100%;
          overflow: hidden;
          .image {
            width: 11.8rem;
            height: 9.8rem;
            margin-right: 1rem;
            .ant-image {
              width: 100%;
              height: 100%;
              cursor: pointer;
              position: relative;
              transition: all 0.5s ease-out;
              &:hover::before {
                content: '';
                position: absolute;
                background-color: rgba(0, 0, 0, 0.362);
                width: 100%;
                height: 100%;
              }
              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                background-color: #ffffff66;
              }
            }
            
          }

          .image:last-child {
            margin-right: 0;
          }
          .container {
            display: flex;
          }
          .not-found {
            text-align: center;
            width: 100%;
            font-size: 1.6rem;
            font-family: $font-primary;
          }
        }
        .details {
          margin-top: 1.2rem;
          margin-bottom: 2rem;
          p {
            font-size: 1.6rem;
            font-family: $font-primary;
            line-height: 2.4rem;
            white-space: pre-line;
          }
        }
        .comment-section {
          border-top: 0.2rem solid rgba(128, 128, 128, 0.158);
          &--heading{
            margin: 1.5rem 0 !important;
          }
          .comment-input {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin: 1rem 0;
            .user-image {
              width: 3rem;
              height: auto;
              border-radius: 100%;
              margin-right: 0.6rem;
              img {
                width: 100%;
                height: 100%;
                border-radius: 100%;
              }
            }
            .send-comment {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              background-color: #ffffff57;
              padding: 1rem 1rem;
              border-radius: 0.5rem;
              textarea {
                width: 100%;
                background-color: transparent;
                border: none;
                outline: none;
                font-size: 1.6rem;
                font-family: $font-primary;
                line-height: 1.8rem;
                resize: none;
                &::placeholder {
                  opacity: 0.7;
                }
              }
              .send-btn {
                border: none;
                height: fit-content;
                width: fit-content;
                line-height: normal;
                margin-left: 1rem;
                cursor: pointer;
                background-color: transparent;
                .icon {
                  color: $color-primary;
                  font-size: 2rem;
                }
              }
            }
          }
          .comment-container {
            .comment {
              &__comment-details {
                .user-details {
                  background-color: #ffffff57;
                }
              }
              .comment-input {
                .send-comment {
                  background-color: #ffffff57;
                }
              }
            }
          }
        }
      }
      .user-media-details::-webkit-scrollbar-track {
        background-color: #ffffff3f;
      }

      .user-media-details::-webkit-scrollbar {
        width: 4px;
        background-color: transparent;
        border-radius: 3rem;
      }

      .user-media-details::-webkit-scrollbar-thumb {
        background-color: $color-primary;
        border-radius: 3rem;
      }
    }
    .footer {
      padding: 2.2rem 4rem;
      background-color: $color-white !important;
      text-align: center;
      position: absolute;
      bottom: 0;
      width: 100%;
      button {
        border: none;
        font-size: 1.8rem;
        font-family: $font-secondary-bold;
        width: 100%;
        padding: 1.6rem 0;
        cursor: pointer;
        background-color: $color-primary;
        color: $color-white;
      }
      .donate-btn {
        background-color: #001b26;
      }
    }
  }
  .story-drawer-skeleton {
    padding: 4rem;
    .ant-skeleton {
      .ant-skeleton-content {
        .ant-skeleton-title {
          height: 10rem;
        }
        .ant-skeleton-paragraph {
          li {
            height: 10rem;
            margin-top: 3rem;
            &:last-child {
              height: 20rem;
            }
          }
        }
      }
    }
  }
   .not-found {
    font-size: 1.8rem;
    font-family: $font-primary-bold;
    text-align: center;
    color: gray;
    margin: 6rem;
  }
}
</style>
